import React, { useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  useTheme
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert, Box, Checkbox, Typography, Link } from "../../../components/mui";
import { StyledCircularProgress } from "../../../components/styled/StyledCircularProgress";
import { TwoColumnForm } from "../../../components/styled/StyledForm";
import Title from "../../../components/Title";
import { useGoogleAnalytics } from "../../../services/analytics/hooks/useGoogleAnalytics";
import { postRegister, setForm } from "../../../store/auth/auth.slice";
import { getInstitutionDetails } from "../../../store/institution/institution.slice";
import { setLoans } from "../../../store/loans/loans.slice";
import { PRIVACY_POLICY_URL } from "../../../utils/constants/constants";
import { constructRegisterPayload } from "../../../utils/helpers/constructRegisterPayload";
import { useMultipleFormErrors } from "../../../utils/hooks/useMultipleFormErrors";
import { schema } from "../../../utils/validators/formHookSchema";
import AccountDetails from "../components/AccountDetails";
import UserInfo from "../components/UserInfo";

const Validation = () => {
  // * Named selectors
  const institutionState = (state) => state.institution;
  const authState = (state) => state.auth;

  // * Use named selectors
  const { config } = useSelector(institutionState);
  const { register: registerState, form: registerForm } = useSelector(authState);

  const member_id = config?.services?.registered?.fields?.member_id;
  const uniqueIdLookupEnabled = config?.services?.unique_id_lookup?.enabled	&& config?.services?.unique_id_lookup?.registered?.enabled;

  // * Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const materialTheme = useTheme();
  const [shouldShowSpinner, setShouldShowSpinner] = useState(false);
  const { register, handleSubmit, formState: { errors: formErrors }, control } = useForm({
    mode: "onBlur",
    defaultValues: {
      firstName: registerForm?.firstName || "",
      lastName: registerForm?.lastName || "",
      emailAddress: registerForm?.emailAddress || "",
      memberId: registerForm?.memberId || "",
      securityAnswer1: registerForm?.securityAnswer1 || "",
      securityAnswer2: registerForm?.securityAnswer2 || ""
    }
  });
  const { errorMessage } = useMultipleFormErrors(formErrors);
  const { trackEvent, actions, categories } = useGoogleAnalytics();

  const executePostRegistration = (form) => {
    if (registerState.isSubmitting) return;
    setShouldShowSpinner(true);

    // Alt config
    const altMethods = config?.authentication_methods;
    const altAuth = altMethods?.alternates || [];
    const hasAlternateMethods = Array.isArray(altAuth) && Boolean(altAuth.length);

    let authScheme;
    if (hasAlternateMethods) {
      const [firstAltAuthMethod] = altAuth;
      authScheme = {
        identifier: firstAltAuthMethod.identifier,
        fields: {
          [firstAltAuthMethod.identifier]: form.authScheme
        }
      };
    }

    const memberId = member_id?.enabled ? form.memberId.replace(/\D?/g, "") : form.memberId;

    Object.assign(form, {
      institutionId: config?.id,
      memberId: hasAlternateMethods ? null : memberId,
      authScheme
    });
    const payload = constructRegisterPayload(form);
    payload.flow = "registration";
    dispatch(postRegister(payload))
      .unwrap()
      .then(res => {
        trackEvent(actions.REGISTRATION, categories.ATTEMPT_SUCCESS);
        dispatch(setLoans(res?.loans));
        dispatch(setForm(form));
        getDetails(res.token, form?.securityAnswer2);
      })
      .catch(() => {
        trackEvent(actions.REGISTRATION, categories.ATTEMPT_FAILURE);
        setShouldShowSpinner(false);
      });
  };

  const getDetails = (token, securityAnswer) => {
    const detailsPayload = { institutionId: config?.id, token };
    dispatch(getInstitutionDetails(detailsPayload))
      .unwrap()
      .then(() => {
        navigate("/create-password", {
          state: {
            securityAnswer,
            institutionId: config?.id
          }
        });
      })
      .catch(() => { setShouldShowSpinner(false); });
  };

  // Config Checks
  if (typeof uniqueIdLookupEnabled !== "boolean") {
    console.warn("Missing enabled value for unique_id_lookup registered path.");
  }
  return (
    <>
      <Title title="Register" subTitle={config?.instructions?.registration?.get_started || ""} />
      <Alert message={errorMessage} severity="error" />
      <Alert message={registerState?.apiError?.response} severity={registerState?.apiError?.severity} />
      <Typography component="p" variant="body2" noWrap>* Required Field </Typography>
      <TwoColumnForm
        materialTheme={materialTheme}
        onSubmit={handleSubmit(executePostRegistration)}
        noValidate={true}
      >
        <UserInfo
          formErrors={formErrors}
          register={register}
          reduxForm={registerForm}
          dispatch={dispatch}
          uniqueIdLookupEnabled={uniqueIdLookupEnabled}
        />

        <AccountDetails
          formErrors={formErrors}
          register={register}
          config={config}
          uniqueIdLookupEnabled={uniqueIdLookupEnabled}
        />

        <Box gridArea="terms-of-service">
          <FormControl component="fieldset" error={!!formErrors?.confirmation}>
            <Controller
              name="confirmation"
              control={control}
              defaultValue={false}
              rules={schema.checkBox.properties}
              render={({
                field: props
              }) => {
                return (
                  <FormControlLabel
                    label={
                      <Box>
                        <Typography component="span">
                          {"By clicking this box you are submitting e-sign consent and agreeing to the "}
                        </Typography>
                        <Link target="_blank" rel="noopener" href={config?.terms_and_conditions_url}>
                              Terms of Service
                        </Link>
                        <Typography component="span">
                          {" and "}
                        </Typography>
                        <Link target="_blank" rel="noopener" href={PRIVACY_POLICY_URL}>
                          Privacy Policy
                        </Link>
                      </Box>
                    }
                    control={
                      <Checkbox {...props} inputProps={{ "aria-required": "true" }} />
                    }
                  />
                );
              }}
            />
            <FormHelperText>{formErrors?.confirmation?.message || " "}</FormHelperText>
          </FormControl>
        </Box>

        <Box sx={{ position: "relative" }} gridArea="button">
          <Button
            color="primary"
            fullWidth
            size="large"
            variant="contained"
            type="submit"
            disabled={shouldShowSpinner}
          >
                  Submit
          </Button>
          {shouldShowSpinner && <StyledCircularProgress />}
        </Box>
      </TwoColumnForm>
    </>
  );
};

export default Validation;
