import React, { useState, useEffect } from "react";
import { TextField, Button, useTheme, Checkbox, FormControlLabel, FormControl, FormHelperText } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { Alert, Box, Typography, Link } from "../../components/mui";
import { StyledCircularProgress } from "../../components/styled/StyledCircularProgress";
import { TwoColumnForm } from "../../components/styled/StyledForm";
import Title from "../../components/Title";
import { useGoogleAnalytics } from "../../services/analytics/hooks/useGoogleAnalytics";
import {
  postExpressPayAuth,
  setFirstName,
  setFirstSecurityQuestion,
  setSecondSecurityQuestion,
  setLastName,
  setMemberAccount,
  setAuthToken,
  resetForm
} from "../../store/auth/auth.slice";
import { getInstitutionDetails } from "../../store/institution/institution.slice";
import { setLoans } from "../../store/loans/loans.slice";
import { PRIVACY_POLICY_URL } from "../../utils/constants/constants";
import { useMultipleFormErrors } from "../../utils/hooks/useMultipleFormErrors";
import { schema } from "../../utils/validators/formHookSchema";
import { validate } from "../../utils/validators/validators";

const ExpressPay = () => {
  // Named selectors
  const institutionState = (state) => state.institution;
  const authState = (state) => state.auth;

  // Pass in named selectors and gets state from redux
  const { config, isInstitutionDetailsLoading } = useSelector(institutionState);
  const { form, guestAuth: { isSubmitting, apiError } } = useSelector(authState);

  // Local State
  const [disableButton, setDisableButton] = useState(false);

  const memberIdEnabled = config?.services?.express_pay?.fields?.member_id?.enabled;
  const uniqueIdLookupEnabled = config?.services?.unique_id_lookup?.enabled	&& config?.services?.unique_id_lookup?.express_pay?.enabled;

  // Alt config
  const altMethods = config?.authentication_methods;
  const altAuth = altMethods?.alternates || [];
  const hasAlternateMethods = Array.isArray(altAuth) && Boolean(altAuth.length);

  let firstAltAuthMethod;

  if (hasAlternateMethods) {
    [firstAltAuthMethod] = altAuth;
  }

  // TODO: Refactor the dynamism of how security questions are rendered with alt config enabled
  const displaySecurityQuestion1 = hasAlternateMethods
    ? !!config?.security_question_1 && firstAltAuthMethod?.use_security_answer_1
    : config?.security_question_1;
  const displaySecurityQuestion2 = hasAlternateMethods
    ? !!config?.security_question_2 && firstAltAuthMethod?.use_security_answer_2
    : false;

  // Hooks
  const options = {
    mode: "onBlur",
    reValidateMode: "onChange",
    shouldFocusError: true
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm(options);
  const { errorMessage } = useMultipleFormErrors(errors);
  const materialTheme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { trackEvent, actions, categories } = useGoogleAnalytics();

  useEffect(() => {
    setDisableButton(isSubmitting || isInstitutionDetailsLoading);
  }, [isSubmitting, isInstitutionDetailsLoading]);

  // Handlers
  const handleFirstName = (event) => {
    dispatch(setFirstName(event.target.value));
  };

  const handleLastName = (event) => {
    dispatch(setLastName(event.target.value));
  };

  const handleMemberAccount = (event) => {
    dispatch(setMemberAccount(event.target.value));
  };

  const handleFirstSecurityQuestion = (event) => {
    dispatch(setFirstSecurityQuestion(event.target.value));
  };

  const handleSecondSecurityQuestion = (event) => {
    dispatch(setSecondSecurityQuestion(event.target.value));
  };


  const handleGuestAuth = (event) => {
    let memberId = null;
    if (!hasAlternateMethods) {
      memberId = memberIdEnabled ? event.memberAccount.replace(/\D?/g, "") : event.memberAccount.trim();
    }
    const authScheme = {
      identifier: firstAltAuthMethod?.identifier,
      fields: {
        [firstAltAuthMethod?.identifier]: event.altAuthField
      }
    };

    const payload = {
      institution_id: config?.id,
      first_name: event.firstName.trim(),
      last_name: event.lastName.trim(),
      email_address: event.emailAddress.toLowerCase().trim(),
      member_id: memberId,
      security_answer_1: event.firstSecurityQuestion.trim(),
      security_answer_2: displaySecurityQuestion2 ? event.secondSecurityQuestion.trim() : null,
      auth_scheme: hasAlternateMethods ? authScheme : null
    };

    dispatch(postExpressPayAuth(payload))
      .unwrap()
      .then((response) => {
        trackEvent(actions.LOGIN_EXPRESS_PAY, categories.ATTEMPT_SUCCESS);
        response.institutionId = config?.id;
        dispatch(setAuthToken(response.token));
        dispatch(setLoans(response.loans));
        dispatch(getInstitutionDetails(response))
          .unwrap()
          .then(() => {
            navigate("/accounts");
            dispatch(resetForm());
          })
          .catch(() => { });
      })
      .catch(() => {
        trackEvent(actions.LOGIN_EXPRESS_PAY, categories.ATTEMPT_FAILURE);
      });
  };

  // Config Checks
  if (typeof uniqueIdLookupEnabled !== "boolean") {
    console.warn("Missing enabled value for unique_id_lookup express_pay path.");
  }

  const sectionStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    border: "none",
    padding: "0px",
    margin: "0px"
  };

  return (
    <>
      <Title title="Express Pay" subTitle={config?.getting_started_text} />
      <Alert message={errorMessage} severity={"error"} />
      <Alert message={apiError?.response} severity={apiError?.severity} />

      <Typography component="p" variant="body2" sx={{ marginBlock: "0.5rem" }}>
        * Required Field
      </Typography>
      <TwoColumnForm
        materialTheme={materialTheme}
        onSubmit={handleSubmit(handleGuestAuth)}
        noValidate={true}
      >
        <Box component="fieldset" sx={sectionStyle} gridArea="left-col">
          <Box>
            <Typography variant="h2">User Information</Typography>
            {uniqueIdLookupEnabled ? <span>&nbsp;</span> : null}
          </Box>
          <TextField
            autoComplete="given-name"
            error={!!errors?.firstName}
            fullWidth
            helperText={errors?.firstName ? errors.firstName.message : " "}
            id="first-name"
            inputProps={{ "aria-required": "true" }}
            label="First name"
            required
            size="medium"
            value={form.firstName}
            variant="outlined"
            {...register("firstName", {
              maxLength: {
                value: 40,
                message: "First name must be less than 40 characters"
              },
              onChange: handleFirstName,
              required: "First name is required",
              pattern: validate.name
            })}
          />
          <TextField
            autoComplete="family-name"
            error={!!errors?.lastName}
            fullWidth
            helperText={errors?.lastName ? errors.lastName.message : " "}
            id="last-name"
            inputProps={{ "aria-required": "true" }}
            label="Last name"
            required
            value={form.lastName}
            variant="outlined"
            {...register("lastName", {
              maxLength: {
                value: 40,
                message: "Last name must be less than 40 characters"
              },
              onChange: handleLastName,
              required: "Last name is required",
              pattern: validate.name
            })}
          />
          <TextField {...schema.email.properties(errors, register, form?.emailAddress, dispatch)} />
        </Box>

        <Box component="fieldset" sx={sectionStyle} gridArea="right-col">
          <Box>
            <Typography variant="h2" component="legend">Account Holder Details</Typography>
            {uniqueIdLookupEnabled
              ?
              (
                <RouterLink to="/id-lookup">
                  I need help finding my {config?.member_id_terminology ? config?.member_id_terminology?.toLowerCase() : "member number"}
                </RouterLink>
              )
              :
              null
            }
          </Box>

          {
            hasAlternateMethods && firstAltAuthMethod
              ?
              <TextField
                autoComplete="off"
                error={!!errors?.altAuthField}
                fullWidth
                helperText={errors?.altAuthField?.message || " "}
                id={firstAltAuthMethod?.identifier}
                inputProps={{ "aria-required": "true" }}
                label={firstAltAuthMethod?.label}
                required
                value={form.firstAltMethod}
                variant="outlined"
                {...register("altAuthField", {
                  maxLength: {
                    value: 100,
                    message: `${firstAltAuthMethod?.label} must be less than 100 characters`
                  },
                  required: `${firstAltAuthMethod?.label} is required`,
                  pattern: {
                    value: /^.+$/,
                    message: "Invalid Input"
                  }
                })}
              />
              :
              <TextField
                autoComplete="off"
                error={!!errors?.memberAccount}
                fullWidth
                helperText={
                  errors?.memberAccount ? errors.memberAccount.message : " "
                }
                id="member-account"
                inputProps={{ "aria-required": "true" }}
                label={config ? config?.member_id_terminology : "Member Number"}
                required
                value={form.memberAccount}
                variant="outlined"
                {...register("memberAccount", {
                  onChange: handleMemberAccount,
                  required: config
                    ? `${config?.member_id_terminology} is required`
                    : "Member Number is required",
                  pattern: {
                    value: new RegExp(config?.services?.express_pay?.fields?.member_id?.regex || ""),
                    message: config?.services?.express_pay?.fields?.member_id?.error_text || ""
                  }
                })}
              />
          }
          {
            displaySecurityQuestion1
              ?
              <TextField
                autoComplete="off"
                error={!!errors?.firstSecurityQuestion}
                fullWidth
                helperText={
                  errors?.firstSecurityQuestion
                    ? errors.firstSecurityQuestion.message
                    : " "
                }
                id="first-security-question"
                inputProps={{ "aria-required": "true" }}
                label={config?.security_question_1}
                required
                value={form.firstSecurityQuestion}
                variant="outlined"
                {...register("firstSecurityQuestion", {
                  onChange: handleFirstSecurityQuestion,
                  required: `${config?.security_question_1} is required`,
                  pattern: {
                    value: new RegExp(
                      config?.security_question_1_regex || "^.+$"
                    ),
                    message: "Invalid input"
                  }
                })}
              />
              :
              null
          }
          {
            displaySecurityQuestion2
              ?
              <TextField
                autoComplete="off"
                error={!!errors?.secondSecurityQuestion}
                fullWidth
                helperText={
                  errors?.secondSecurityQuestion
                    ? errors.secondSecurityQuestion.message
                    : " "
                }
                id="second-security-question"
                inputProps={{ "aria-required": "true" }}
                label={config?.security_question_2}
                required
                value={form.secondSecurityQuestion}
                variant="outlined"
                {...register("secondSecurityQuestion", {
                  onChange: handleSecondSecurityQuestion,
                  required: `${config?.security_question_2} is required`,
                  pattern: {
                    value: new RegExp(
                      config?.security_question_2_regex || "^.+$"
                    ),
                    message: "Invalid input"
                  }
                })}
              />
              :
              null
          }
        </Box>

        <Box gridArea="terms-of-service">
          <FormControl error={!!errors?.confirmation}>
            <Controller
              name="confirmation"
              control={control}
              defaultValue={false}
              rules={schema.checkBox.properties}
              render={({
                field: props
              }) => {
                return (
                  <FormControlLabel
                    label={
                      <Box>
                        <Typography component="span">
                          {"By clicking this box you are submitting e-sign consent and agreeing to the "}
                        </Typography>
                        <Link target="_blank" rel="noopener" href={config?.terms_and_conditions_url}>
                          Terms of Service
                        </Link>
                        <Typography component="span">
                          {" and "}
                        </Typography>
                        <Link target="_blank" rel="noopener" href={PRIVACY_POLICY_URL}>
                          Privacy Policy
                        </Link>
                      </Box>
                    }
                    control={
                      <Checkbox {...props} inputProps={{ "aria-required": "true" }} />
                    }
                  />
                );
              }}
            />
            <FormHelperText>{errors?.confirmation?.message || " "}</FormHelperText>
          </FormControl>
        </Box>

        <Box sx={{ position: "relative" }} gridArea="button">
          <Button
            color="primary"
            disabled={disableButton}
            fullWidth
            aria-label="Click to continue with express pay"
            size="large"
            sx={{ maxWidth: { xs: "xs", md: "md" } }}
            type="submit"
            variant="contained"
          >
            Continue with Express Pay
          </Button>
          {disableButton && <StyledCircularProgress />}
        </Box>
      </TwoColumnForm>
    </>
  );
};

export default ExpressPay;
