import React, { useEffect, useState } from "react";
import { TextField, useTheme } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate, useSearchParams } from "react-router-dom";
import PasswordComponent from "../../components/formHookComponents/PasswordComponent";
import { Alert, Box, Button, Typography, Link } from "../../components/mui";
import Title from "../../components/Title";
import { useGoogleAnalytics } from "../../services/analytics/hooks/useGoogleAnalytics";
import { deleteAuthToken, postLogin, resetForm, setEmail } from "../../store/auth/auth.slice";
import { getInstitutionDetails } from "../../store/institution/institution.slice";
import { PRIVACY_POLICY_URL } from "../../utils/constants/constants";
import { getLoginApiResponse } from "../../utils/data/LoginApiResponses";
import { schema } from "../../utils/validators/formHookSchema";
import { Container } from "./styles";

const LoginPage = () => {
  // Named selectors
  const institutionState = (state) => state.institution;
  const authState = (state) => state.auth;

  // Pass in named selectors and gets state from redux
  const { config, isInstitutionDetailsLoading } = useSelector(institutionState);
  const { login: { isSubmitting, loginError: apiError }, form } = useSelector(authState);

  // Hooks
  const { register, handleSubmit, formState: { errors: formErrors } } = useForm({ mode: "onBlur" });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const materialTheme = useTheme();
  const { trackEvent, actions, categories } = useGoogleAnalytics();

  const [searchParams] = useSearchParams();
  const institutionId = searchParams.get("institutionId");

  const initialAlertSettings = {
    message: "",
    severity: ""
  };
  const [alert, setAlert] = useState(initialAlertSettings);

  useEffect(() => {
    if (!apiError) return;
    const formattedError = getLoginApiResponse(apiError, config);
    const { response, severity } = formattedError;
    setAlert({ message: response, severity });
  }, [apiError, config]);

  const executePostLogin = (formValues) => {
    setAlert(initialAlertSettings);
    Object.assign(formValues, { institutionId: config.id });
    dispatch(postLogin(formValues))
      .unwrap()
      .then((response) => {
        trackEvent(actions.LOGIN_REGISTERED, categories.ATTEMPT_SUCCESS);
        dispatch(setEmail(formValues.email));
        response.institutionId = formValues?.institutionId;
        dispatch(getInstitutionDetails(response))
          .unwrap()
          .then(() => {
            navigate("/accounts");
            dispatch(resetForm());
          })
          .catch(() => {
            setAlert({ message: "An unexpected error has occurred, please try again.", severity: "error" });
            dispatch(deleteAuthToken());
          });
      })
      .catch(() => {
        trackEvent(actions.LOGIN_REGISTERED, categories.ATTEMPT_FAILURE);
      });
  };

  return (
    <>
      <Title title={config ? config?.brand_name : "Login"} subTitle={config?.notice_text} />
      <Alert message={alert.message} severity={alert.severity || "error"} />

      <Container materialTheme={materialTheme}>
        <form className="login-form" onSubmit={handleSubmit(executePostLogin)} noValidate={true}>
          <Typography component="p" variant="body2">* Required Field </Typography>
          <TextField {...schema.email.properties(formErrors, register, form?.emailAddress, dispatch)} />
          <PasswordComponent formErrors={formErrors} register={register} />
          <Button
            disabled={isSubmitting || isInstitutionDetailsLoading}
            loading={isSubmitting || isInstitutionDetailsLoading}
            fullWidth
            size="large"
            type="submit"
            sx={{ maxWidth: { md: "md", xs: "xs" } }}
            variant="contained"
          >
            Login
          </Button>
        </form>

        <section className="buttons-container">
          {
            config?.express_pay_enabled
              ?
              <Button
                color="tertiary"
                component={RouterLink}
                fullWidth
                size="large"
                to={`/express-pay?institutionId=${institutionId}`}
                variant="contained"
              >
                Express Pay
              </Button>
              : null
          }
          <Button
            color="secondary"
            component={RouterLink}
            fullWidth
            size="large"
            to={`/register?institutionId=${institutionId}`}
            variant="contained"
          >
              Register
          </Button>
        </section>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Link component={RouterLink} to="/reset-password"> Forgot password? </Link>
          <Typography component="span" sx={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}>
            {" | "}
          </Typography>
          <Link target="_blank" rel="noopener" href={PRIVACY_POLICY_URL}>Privacy Policy</Link>
        </Box>
      </Container>
    </>
  );
};

export default LoginPage;
