import React from "react";
import { FormControlLabel, FormHelperText, Link, Checkbox, FormControl, useTheme } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert, Box, Typography, Button } from "../../../components";
import { TwoColumnForm } from "../../../components/styled/StyledForm";
import Title from "../../../components/Title";
import { setNonValidationPayload } from "../../../store/auth/auth.slice";
import { PRIVACY_POLICY_URL } from "../../../utils/constants/constants";
import { useMultipleFormErrors } from "../../../utils/hooks/useMultipleFormErrors";
import { schema } from "../../../utils/validators/formHookSchema";
import AccountDetails from "../components/NonValidationAccountDetails";
import UserInfo from "../components/UserInfo";

const NonValidation = () => {
  // Named Selectors
  const institutionState = (state) => state.institution;
  const authState = (state) => state.auth;

  // Redux State
  const { config } = useSelector(institutionState);
  const { form: reduxForm, register: { nonValidation } } = useSelector(authState);

  // Hooks
  const getDefaultValues = () => {
    const staticValues = {
      firstName: nonValidation?.payload?.first_name || "",
      lastName: nonValidation?.payload?.last_name || "",
      emailAddress: nonValidation?.payload?.email_address || reduxForm?.emailAddress || "",
      memberId: nonValidation?.payload?.member_id || ""
    };

    config?.no_validation_file?.registration_fields?.forEach((field, index) => {
      const data = nonValidation?.payload?.registration_fields?.find(data => data.label === field.label);
      staticValues[`registration_fields_${index}`] = data?.value || "";
    });

    return staticValues;
  };

  const { register, handleSubmit, formState: { errors: formErrors }, control } = useForm({
    mode: "onBlur",
    defaultValues: getDefaultValues()
  });
  const { errorMessage } = useMultipleFormErrors(formErrors);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const materialTheme = useTheme();

  const executePostRegistration = (form) => {
    const registrationFields = config?.no_validation_file?.registration_fields?.map((field, index) => {
      const data = {
        label: field.label,
        secure: field.secure,
        value: form[`registration_fields_${index}`]
      };
      return data;
    });

    const payload = {
      email_address: form.emailAddress,
      first_name: form.firstName,
      institution_id: config.id,
      last_name: form.lastName,
      member_id: form.memberId,
      password: "",
      registration_fields: registrationFields
    };
    dispatch(setNonValidationPayload(payload));
    navigate("/non-validation/create-password");
  };

  return (
    <>
      <Title title="Register" subTitle={config?.instructions?.registration?.get_started || ""} />
      <Alert message={errorMessage} severity="error" />
      <Typography component="p" variant="body2" noWrap>* Required Field </Typography>
      <TwoColumnForm
        materialTheme={materialTheme}
        onSubmit={handleSubmit(executePostRegistration)}
        noValidate={true}
      >

        <UserInfo
          formErrors={formErrors}
          register={register}
          reduxForm={reduxForm}
          dispatch={dispatch}
          uniqueIdLookupEnabled={false}
        />

        <AccountDetails
          formErrors={formErrors}
          register={register}
          config={config}
        />

        <Box gridArea="terms-of-service">
          <FormControl component="fieldset" error={!!formErrors?.confirmation}>
            <Controller
              name="confirmation"
              control={control}
              defaultValue={false}
              rules={schema.checkBox.properties}
              render={({
                field: props
              }) => {
                return (
                  <FormControlLabel
                    label={
                      <Box>
                        <Typography component="span">
                          {"By clicking this box you are submitting e-sign consent and agreeing to the "}
                        </Typography>
                        <Link target="_blank" rel="noopener" href={config?.terms_and_conditions_url}>
                          Terms of Service
                        </Link>
                        <Typography component="span">
                          {" and "}
                        </Typography>
                        <Link target="_blank" rel="noopener" href={PRIVACY_POLICY_URL}>
                          Privacy Policy
                        </Link>
                      </Box>
                    }
                    control={
                      <Checkbox {...props} inputProps={{ "aria-required": "true" }} />
                    }
                  />
                );
              }}
            />
            <FormHelperText>{formErrors?.confirmation?.message || " "}</FormHelperText>
          </FormControl>
        </Box>

        <Box sx={{ position: "relative" }} gridArea="button">
          <Button
            color="primary"
            type="submit"
            disabled={nonValidation.isSubmitting}
            loading={nonValidation?.isSubmitting}
          >
            Create Password
          </Button>
        </Box>
      </TwoColumnForm>
    </>
  );
};

export default NonValidation;
